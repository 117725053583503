import React from 'react';
import FooterSlot from '@openedx/frontend-slot-footer';

const Footer = () => (
  <div style={{ paddingBottom: '2%' }}>
    <FooterSlot />
  </div>
);

export default Footer;
